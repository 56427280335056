import React, { useState } from "react"
import { graphql } from "gatsby"
import { SEO, Layout, Book } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!) {
    sanityBooks(slug: { current: { eq: $slug } }) {
      title
      author {
        name
        slug {
          current
        }
      }
      description
      cover {
        asset {
          url
          gatsbyImage(width: 800, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      _rawProductDetails
      _rawProductDescription
      formats {
        format
        price
        link
      }
      sample {
        asset {
          url
          title
          path
        }
      }
      amazonIcon {
        asset {
          gatsbyImageData(width: 36, placeholder: NONE)
        }
      }
      sampleIcon {
        asset {
          gatsbyImageData(width: 36, placeholder: NONE)
        }
      }
      slug {
        current
      }
    }
  }
`

const BookTemplate = ({ data }) => {
  let book = data.sanityBooks
  let {
    title,
    author,
    description,
    cover,
    formats,
    sample,
    amazonIcon,
    sampleIcon,
  } = book
  sample = sample.asset

  const breakpoint = 920
  const defaultWidth = typeof window !== "undefined" && window.innerWidth
  const [width, setWidth] = React.useState(defaultWidth)
  const [showBook, setShowBook] = React.useState(false)
  React.useEffect(() => {
    setShowBook(true)
    const handleResizeWindow = () => setWidth(window.innerWidth)
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow)
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow)
    }
  }, [])

  const [format, setFormat] = useState(0)

  return (
    <Layout>
      <Book>
        <Book.Links>
          {showBook && width < breakpoint && (
            <>
              <Book.Title>{title}</Book.Title>
              <Book.Author name={author.name} link={author.slug.current} />
              <Book.Formats setFormat={setFormat} formats={formats} />
            </>
          )}
          <Book.Cover src={cover} alt={title} />

          {showBook && width < breakpoint && (
            <Book.Buttons>
              <Book.Purchase format={formats[format]} icon={amazonIcon} />
              <Book.Sample
                file={sample.url}
                altText={sample.title}
                icon={sampleIcon}
              >
                Free Chapter
              </Book.Sample>
            </Book.Buttons>
          )}
        </Book.Links>
        <Book.Body>
          {showBook && width >= breakpoint && (
            <>
              <Book.Title>{title}</Book.Title>
              <Book.Author name={author.name} link={author.slug.current} />
              <Book.Formats setFormat={setFormat} formats={formats} />
            </>
          )}

          {showBook && width >= breakpoint && (
            <Book.Buttons>
              <Book.Purchase format={formats[format]} icon={amazonIcon} />
              <Book.Sample
                file={sample.url}
                altText={sample.title}
                icon={sampleIcon}
              >
                Free Chapter
              </Book.Sample>
            </Book.Buttons>
          )}

          {showBook && (
            <>
              <Book.Description description={book._rawProductDescription} />
              <Book.Details details={book._rawProductDetails} />
            </>
          )}
        </Book.Body>
      </Book>
    </Layout>
  )
}

export default BookTemplate

export function Head({ data }) {
  let { title, description } = data.sanityBooks

  return <SEO title={title} description={description} />
}
